import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.attachCreditCommentsListeners();
  }

  attachCreditCommentsListeners() {
    const creditCommentsPopups = this.element.querySelectorAll('.credit-comments-popup');
    const openCreditCommentsBtns = this.element.querySelectorAll('.open-credit-comments-popup');

    if (creditCommentsPopups.length === 0 || openCreditCommentsBtns.length === 0) {
      return;
    }

    const openCreditCommentsPopup = (popup) => {
      popup.style.visibility = 'visible';
    };

    const closeCreditCommentsPopup = (popup) => {
      popup.style.visibility = 'hidden';
    };

    const handleOpenClick = (event) => {
      const popup = event.target.closest('.comment-box').querySelector('.credit-comments-popup');
      if (popup) {
        openCreditCommentsPopup(popup);
      }
    };

    openCreditCommentsBtns.forEach((btn) => {
      btn.removeEventListener('click', handleOpenClick);
      btn.addEventListener('click', handleOpenClick);
    });

    creditCommentsPopups.forEach((popup) => {
      const closeBtn = popup.querySelector('.close-credit-comments-popup');

      const handleCloseClick = () => {
        closeCreditCommentsPopup(popup);
      };

      if (closeBtn) {
        closeBtn.removeEventListener('click', handleCloseClick);
        closeBtn.addEventListener('click', handleCloseClick);
      }

      popup.addEventListener('click', (event) => {
        if (event.target === popup) {
          closeCreditCommentsPopup(popup);
        }
      });
    });
  }
}
