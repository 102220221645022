document.addEventListener("turbo:load", function () {
  const editButton = document.getElementById('edit-button');

  const cancelButton = document.getElementById('cancel-button');

  const infoCardDefault = document.getElementById('info-card-default');
  const infoCardEdit = document.getElementById('info-card-edit');

  if (editButton) {
    editButton.addEventListener('click', function () {
      infoCardDefault.style.display = 'none';
      infoCardEdit.style.display = 'block';
    });

    cancelButton.addEventListener('click', function () {
      infoCardDefault.style.display = 'block';
      infoCardEdit.style.display = 'none';
    });
  }
});

document.querySelectorAll('.statusSelect').forEach(function(select) {
  select.addEventListener('change', function() {
    var formId = this.dataset.formId;
    document.getElementById(formId).submit();
  });
});

document.querySelectorAll('.datetimeSelect').forEach(function(select) {
  select.addEventListener('change', function() {
    var formId = this.dataset.formId;
    document.getElementById(formId).submit();
  });
});
