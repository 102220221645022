import { Turbo } from "@hotwired/turbo-rails"
Turbo.session.drive = true

import moment from 'moment'
window.moment = moment

import "./controllers"

require("./packs/popup")
require("./packs/client")
require("./packs/header")
require("./packs/download_notice")
require("./packs/read_more")
require("./packs/create_sale")
require("./packs/checked_months")
require("./packs/credit")
