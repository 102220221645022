document.addEventListener('DOMContentLoaded', () => {
  const updateCheckedMonths = (saleId, month, value) => {
    fetch(`/sales/${saleId}/update_checked_months`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content
      },
      body: JSON.stringify({
        sale: { checked_months: { [month]: value } }
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === 'ok') {
          const box = document.querySelector(`.delivery-box[data-sale-id="${saleId}"][data-month="${month}"]`);
          if (box) {
            box.classList.remove('delivery-bank', 'delivery-empty', 'delivery-default', 'payment-notification');
            if (value) {
              box.classList.add(value === 'bank' ? 'delivery-bank' : 'delivery-empty');
            } else {
              box.classList.add('delivery-default');
              if (isWithinTwoDaysBefore(box.querySelector('a')?.textContent)) {
                box.classList.add('payment-notification');
              }
            }
          }

          const countElement = document.querySelector(`.checked-months-count[data-sale-id="${saleId}"]`);
          if (countElement) {
            countElement.textContent = `${Object.values(data.checked_months || {}).filter(v => v).length}/${countElement.dataset.totalMonths}`;
          }
        } else {
          console.error('Failed to update checked months:', data.errors);
        }
      })
      .catch(error => console.error('Error updating checked months:', error));
  };

  function isWithinTwoDaysBefore(paymentDateStr) {
    let paymentDate = parseDate(paymentDateStr);
    let today = new Date();

    let twoDaysBefore = new Date(paymentDate);
    twoDaysBefore.setDate(paymentDate.getDate() - 2);

    return today >= twoDaysBefore && today <= paymentDate;
  }

  function parseDate(dateStr) {
    let [day, month, year] = dateStr.split('.').map(Number);
    return new Date(year, month - 1, day);
  }

  function attachEventListeners() {
    document.querySelectorAll('.checkbox-bank, .checkbox-empty').forEach(checkbox => {
      if (!checkbox.dataset.listenerAttached) { // Уникнення дублювання обробників
        checkbox.dataset.listenerAttached = 'true';
        checkbox.addEventListener('change', event => {
          const checkbox = event.target;
          const saleId = checkbox.dataset.saleId;
          const month = checkbox.dataset.month;
          const value = checkbox.checked ? checkbox.value : null;

          document.querySelectorAll(`.checkbox-bank[data-sale-id="${saleId}"][data-month="${month}"], .checkbox-empty[data-sale-id="${saleId}"][data-month="${month}"]`).forEach(otherCheckbox => {
            if (otherCheckbox !== checkbox) {
              otherCheckbox.checked = false;
            }
          });

          updateCheckedMonths(saleId, month, value);
        });
      }
    });
  }

  attachEventListeners();

  const observer = new MutationObserver(mutations => {
    mutations.forEach(mutation => {
      if (mutation.addedNodes.length > 0) {
        attachEventListeners();
      }
    });
  });

  observer.observe(document.body, { childList: true, subtree: true });
});
