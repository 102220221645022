import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.attachCreditPaymentsListeners();
  }

  attachCreditPaymentsListeners() {
    const creditPaymentsPopups = this.element.querySelectorAll('.credit-payments-popup');
    const openCreditPaymentsBtns = this.element.querySelectorAll('.open-credit-payments-popup');

    if (creditPaymentsPopups.length === 0 || openCreditPaymentsBtns.length === 0) {
      return;
    }

    const openCreditPaymentsPopup = (popup) => {
      popup.style.visibility = 'visible';
    };

    const closeCreditPaymentsPopup = (popup) => {
      popup.style.visibility = 'hidden';
    };

    const handleOpenClick = (event) => {
      const popup = event.target.closest('.delivery-box').querySelector('.credit-payments-popup');
      if (popup) {
        openCreditPaymentsPopup(popup);
      }
    };

    openCreditPaymentsBtns.forEach((btn) => {
      btn.removeEventListener('click', handleOpenClick); // Уникаємо дублювання
      btn.addEventListener('click', handleOpenClick);
    });

    creditPaymentsPopups.forEach((popup) => {
      const closeBtn = popup.querySelector('.close-credit-payments-popup');

      const handleCloseClick = () => {
        closeCreditPaymentsPopup(popup);
      };

      if (closeBtn) {
        closeBtn.removeEventListener('click', handleCloseClick);
        closeBtn.addEventListener('click', handleCloseClick);
      }

      popup.addEventListener('click', (event) => {
        if (event.target === popup) {
          closeCreditPaymentsPopup(popup);
        }
      });
    });
  }
}
