document.addEventListener('DOMContentLoaded', function () {
  const buttons = document.querySelectorAll('.button-hide');

  buttons.forEach(button => {
    button.addEventListener('click', function () {
      const parentBox = button.closest('.box');
      if (parentBox) {
        const monthClass = parentBox.getAttribute('data-month-class');

        if (monthClass) {
          document.querySelectorAll(`.${monthClass}`).forEach(element => {
            element.classList.toggle('hiden');
          });
        }
      }
    });
  });

  document.querySelectorAll('.box').forEach(box => {
    const paid = parseInt(box.getAttribute('data-paid')) || 0;
    const total = parseInt(box.getAttribute('data-total')) || 0;

    if (paid === total) {
      const monthClass = box.getAttribute('data-month-class');
      if (monthClass) {
        document.querySelectorAll(`.${monthClass}`).forEach(element => {
          element.classList.add('hiden');
        });
      }
    }
  });
});
