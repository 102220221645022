document.addEventListener('DOMContentLoaded', function () {
  const checkFileInput = () => {
    const fileInputs = document.querySelectorAll('.file-input');
    const noFiles = document.querySelectorAll('.no-file');
    const downloadNotices = document.querySelectorAll('.download-notice');

    fileInputs.forEach((fileInput, index) => {
      const notice = noFiles[index];
      const downloadNotice = downloadNotices[index];

      fileInput.addEventListener('change', function () {
        if (this.files.length === 0) {
          notice.style.display = 'block';
          downloadNotice.classList.remove('active');
        } else {
          notice.style.display = 'none';
          downloadNotice.classList.add('active');
        }
      });
    });
  };

  const observer = new MutationObserver(checkFileInput);

  observer.observe(document.body, {
    childList: true,
    subtree: true,
    attributes: false,
    characterData: false
  });

  checkFileInput(); // Перевіряємо наявність елемента .file-input при ініціалізації
});
